<template>
  <ul
    v-for="(visitors, sortKey) in hosts"
    :key="sortKey"
    class="clebex-item-section pill reduced-top-margin"
  >
    <li
      class="clebex-item-section-label-inner"
      v-if="sortKey !== '' && group !== 'user.full_name'"
    >
      <div class="clebex-label-content-wrapper">
        <div class="label">
          <span class="highlight">{{ sortKey }}</span>
        </div>
      </div>
    </li>
    <visitors-table
      v-if="sortKey !== ''"
      :filtered-visitors="visitors"
      :visitor-statuses="visitorStatuses"
      :format-arrival-date="formatArrivalDate"
      :group="group"
      :sortKey="sortKey"
    />
  </ul>
</template>

<script>
import VisitorsTable from "@/components/visitor-management/ReceptionDesk/VisitorsTable";

export default {
  name: "VisitorsGrouped",
  components: {
    VisitorsTable
  },
  props: {
    filteredVisitors: {
      type: [null, Object],
      required: true
    },
    visitorStatuses: {
      type: Object,
      required: true
    },
    formatArrivalDate: {
      type: Function,
      required: true
    },
    group: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      hosts: {}
    };
  },
  watch: {
    filteredVisitors: {
      handler() {
        this.hosts = this.filteredVisitors;
      }
    }
  }
};
</script>
