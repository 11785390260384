<template>
  <li
    v-for="(visitor, index) in filteredVisitors"
    :key="index"
    class="clebex-item-section-item"
  >
    <div class="clebex-item-content-wrapper">
      <div class="checkbox tiny alt ">
        <input
          type="checkbox"
          :id="`visitor-list-${sortKey}-${index}-${visitor[0].id}`"
          name="visitor"
          :checked="visitor[0].is_selected"
          @change="changeChecked(index)"
        />
        <label :for="`visitor-list-${sortKey}-${index}-${visitor[0].id}`">
          <svg-icon icon="checkmark-strait"></svg-icon>
        </label>
      </div>
      <icon
        :class="[
          visitor[0].is_visitor && !visitor[0].self_registered
            ? 'svg-icon-self-registered'
            : '',
          $route.name.includes('my-visitors') ? 'svg-icon-my-visitors' : ''
        ]"
        :icon="getIcon(visitor)"
        width="55"
        height="28"
        @click="openStatusDialog(visitor, index)"
      />
      <router-link
        class="visitor-edit-link"
        :to="{
          name: routeEditVisitor,
          params: {
            visitorId: visitor[0].id
          }
        }"
      >
        <div class="visitor-information">
          <!-- first and last name -->
          <span class="label">
            <span class="highlight">{{
              visitor ? visitor[0].full_name : emptyValue
            }}</span>
            <span class="inner-icons">
              <span
                v-if="
                  visitor[0].license_plate && visitor[0].license_plate.length
                "
                class="declarable-icon"
                ><icon icon="#cx-vis1-parking-12x12"
              /></span>
              <span v-if="hasNotes(visitor)" class="declarable-icon"
                ><icon icon="#cx-vis1-warning-12x12" />
              </span>
              <span v-if="visitor[0].disability" class="declarable-icon"
                ><icon icon="#cx-vis1-disability-12x12" />
              </span>
            </span>
          </span>
          <!-- company -->
          <span class="label">
            <span class="highlight">{{
              visitor[0].company ? visitor[0].company : emptyValue
            }}</span>
          </span>
          <!-- host -->
          <span class="label">
            <span class="highlight">{{
              visitor && visitor.length === 1 && visitor[0].host
                ? visitor[0].host.full_name
                : emptyValue
            }}</span>
          </span>
          <!-- arrival -->
          <span class="label">
            <span class="highlight">{{
              visitor && visitor.length === 1
                ? formatArrivalDate(visitor[0].visit.datetime_from)
                : emptyValue
            }}</span>
          </span>
        </div>
        <span class="follow-up-icons">
          <span class="follow-up-icon-item">
            <icon icon="#cx-app1-arrow-right-12x12"></icon>
          </span>
        </span>
      </router-link>
    </div>
  </li>
</template>

<script>
import { tableFieldDefaultValue } from "@/services/visitor-management";
import { mapActions, mapState } from "vuex";

export default {
  name: "VisitorsTable",
  props: {
    filteredVisitors: {
      type: [null, Object],
      required: true
    },
    visitorStatuses: {
      type: Object,
      required: true
    },
    formatArrivalDate: {
      type: Function,
      required: true
    },
    group: {
      type: String,
      required: true
    },
    sortKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      emptyValue: tableFieldDefaultValue
    };
  },
  computed: {
    ...mapState("visitorManagement", ["modalQueue", "visitors"]),
    routeEditVisitor() {
      if (this.$route.name.includes("my-visitors")) {
        return "r_my-visitors-reception-desk-edit-visitor";
      }
      return "r_reception-desk-edit-visitor";
    }
  },
  methods: {
    ...mapActions("visitorManagement", [
      "getReceptionDeskInfo",
      "getReceptionDeskVisitors",
      "pushToModalQueue"
    ]),
    changeChecked(index) {
      const newList = this.visitors[this.group][this.sortKey][index].map(v => {
        return { ...v, is_selected: !v.is_selected };
      });

      this.visitors[this.group][this.sortKey][index] = newList;

      this.$store.commit(
        "visitorManagement/updateReceptionDeskVisitors",
        this.visitors,
        { root: true }
      );
    },
    openStatusDialog(visitor, index) {
      if (this.$route.name == "r_my-visitors-reception-desk") {
        return;
      }
      if (!visitor[0].is_selected) {
        this.changeChecked(index);
      }

      let data = [];
      Object.values(this.visitors[this.group]).forEach(group => {
        Object.values(group).forEach(visits => {
          visits.forEach(visit => {
            if (visit.is_selected) {
              data.push(visit);
            }
          });
        });
      });

      this.$store.commit("visitorManagement/setModalQueue", data, {
        root: true
      });

      this.$store.commit("visitorManagement/setShowStatusDialog", true, {
        root: true
      });
    },
    /**
     * In case of multiple visits we set mixed badge
     */
    getIcon(visitor) {
      let status = visitor.length === 1 ? visitor[0].visit.visit_status_id : 0;
      const ttt = this.visitorStatuses.find(s => s.status === status);
      return ttt.icon;
    },
    hasNotes(visitor) {
      return visitor.find(v => v.visit.notes);
    }
  }
};
</script>
